@import "variables";

.priceItem {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  align-items: baseline;

  h3 {
    font-size: 13px;
    margin: 0;
    font-weight: normal;
  }
}

.remaining {
  font-size: 14px;
}

.total {
  padding-top: 16px;
  font-size: 18px;

  h3 {
    font-size: 14px;
  }

  > * {
    font-weight: 700 !important;
  }
}

.lineItem {
  padding-top: 4px;
}

.divider {
  margin-top: 16px;
}
