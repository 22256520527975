@import "colors";

$action-color: $green;
$disabled-color: $gray;
$error-color: $red;
$text-color: $dark-gray;
$description-color: $light-gray;

$header-promo-height: 28px;
$header-height: 60px;
$header-height-mobile: 40px;

$font-size-base: 14px;
$margin-base: 10px;
$padding-base: 10px;

$font-primary: "font-local", "font-primary", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif;
$font-secondary: "font-secondary", "Times New Roman", "Times", "Baskerville",
  "Georgia", serif;

$z-index-modal: 10001;
$z-index-header: $z-index-modal - 1;

// BREAKPOINT WIDTHS
// simplified version of https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
$mobile-min: 320px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1024px;
$laptop-min: 1025px;

// BREAKPOINTS
@mixin small-mobile {
  @media (max-width: #{$mobile-min}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-laptop {
  @media (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$tablet-min}) {
    @content;
  }
}

@mixin panel-heading {
  color: $black;
  font-family: $font-secondary;
  font-size: $font-size-base;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

// input mixins
@mixin form-element-border {
  border: none;
  border-bottom: 1px solid $medium-dark-gray;
}

@mixin form-element-focus-border {
  // TODO: check if theme is needed here
  border-bottom: 1px solid $form-blue;
  outline: none;
}

@mixin form-element-success-border {
  border-bottom: 1px solid $action-color !important;
}

@mixin form-element-error-border {
  border-bottom: 1px solid $red !important;
}

@mixin form-label {
  position: absolute;
  transition: transform 0.2s, color 0.2s;
  transform-origin: 0 0;
  font-size: 16px;
  line-height: 1;
  color: $medium-dark-gray;
}

@mixin form-input {
  width: 100%;
  height: 30px;
  font: inherit;
  font-size: 16px;
  background-color: transparent;
  @include form-element-border;

  &:focus {
    @include form-element-focus-border;
  }
}

@mixin form-error {
  font-size: 12px;
  line-height: 1.3;
  color: $error-color;
  display: block;
  margin-top: 5px;
}
