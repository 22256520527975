$dark-gray: #222222;
$gray: #999999;
$medium-dark-gray: #666666;
$light-gray: #f6f6f6;
$lightest-gray: #bcbcbc;
$border-gray: #e5e3e7;
$green: #00883f;
$red: #a62635;
$brown: #8e6451;
$white: #fff;
$black: #0f1116;
$form-blue: #1069d1;
$light-orange: #e07647;
