@import "variables";

.checkbox {
  margin: $margin-base 0 $margin-base * 3 0;
  width: 100%;

  label {
    display: flex;
    width: 100%;
    min-height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: $medium-dark-gray;

    div:first-child {
      margin-right: 5px;
    }
  }
}
