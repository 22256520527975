@import "variables";

@include not-mobile {
  .columnItem {
    & + .columnItem {
      padding-left: $padding-base;
    }

    &:not(:last-child) {
      padding-right: $padding-base;
    }
  }
}
