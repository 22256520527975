@import "variables";

.body {
  height: 450px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include mobile {
    min-height: 100px;
    min-width: 100px;
    height: 100%;
    width: 100%;
  }
}

.orderConfirmationBody {
  height: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.error {
  display: block;
  color: $red;
  line-height: 1.5;
}

.errorContainer {
  padding-top: $padding-base * 2;

  p {
    padding-bottom: $padding-base;
  }
  .error:not(:first-child) {
    margin-top: $margin-base;
  }
}
