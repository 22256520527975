@import "variables";

.caretWrapper {
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  position: relative;

  .upCaret {
    transform: rotate(180deg);
  }
}

.expandedSection {
  padding-left: 10px;
}
