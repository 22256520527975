@import "variables";

.text {
  font-size: $font-size-base + 4;
  margin: $margin-base * 2 0 0;
}

.summary {
  border: 1px solid #999;
  margin: $margin-base * 2 0 $margin-base * 2 0;
  padding: 20px;
}

.errorText {
  color: $red;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: $padding-base * 2;
  margin-top: auto;

  button:nth-child(2) {
    margin-left: $margin-base * 2;
  }

  @include mobile {
    justify-content: center;
  }
}
