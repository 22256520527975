@import "variables";

// Braintree Hosted Fields overrides

.braintree-hosted-fields-focused {
  // TODO: check if theme is needed here
  border-bottom: 2px solid $form-blue !important; // @mixin form-element-focus-border
}

.v3-credit-card-details-form .braintree-hosted-fields-focused {
  // TODO: check if theme is needed here
  border: 2px solid $form-blue !important;
}

// react-modal overrides

.ReactModal {
  &__Overlay {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.75);

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    overflow: hidden;
  }
}

// don't do transition on IE, b/c BUG
.is-modern-browser {
  .ReactModal__Overlay {
    transition: opacity 250ms ease-in-out;
  }
}

// react-transition-group

.slide-enter {
  transform: translateX(50vw);
  position: absolute;

  &-active {
    transform: translateX(0);
    transition: all 200ms linear;
  }
}

.slide-exit {
  transform: translateX(0);

  &-active {
    transform: translateX(-50vw);
    transition: all 200ms linear;
  }
}

// https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
// Expose a hook for JavaScript when autofill is shown
// JavaScript can capture 'animationstart' events

@keyframes onAutofillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}
@keyframes onAutofillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}
// Here we are selecting the previous sibling of the input field this way
// https://stackoverflow.com/a/36118012/10697464
input.input-field:-webkit-autofill {
  animation-name: onAutofillStart;

  & + span.input-field-label {
    transform: scale(0.8) translate(0, -25px);
    transition: transform 0.2s, color 0.2s;
  }

  &:last-child {
    order: -1;
  }
}
input.input-field:not(:-webkit-autofill) {
  animation-name: onAutofillCancel;
}
