@import "variables";

.promoCodeWrapper {
  font-size: 13px;
}

.applyPromoCode {
  color: $form-blue;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.discountPrice {
  color: $action-color;
  font-weight: 700;
}

.error {
  color: $red;
  padding-top: 10px;
}

.inputField {
  border: 1px solid $medium-dark-gray;
  border-radius: 4px;
  color: $dark-gray;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  padding: 12px 46px 12px 10px;
  width: 100%;
}

.promo {
  margin: 10px 0 20px;
}

.promoApplied {
  display: flex;
  justify-content: space-between;
}

.promoContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.promoText {
  display: flex;
  align-items: center;
  color: $form-blue;
}

.remove {
  background: transparent;
  border: 0;
  color: #85888a;
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  line-height: 13px;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}
