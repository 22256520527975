@import "variables";

.title {
  padding-bottom: 20px;
}

.text {
  font-size: $font-size-base + 2;
  display: inline;
}

.contact {
  display: inline;
  padding-left: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: $margin-base * 5;

  @include mobile {
    justify-content: center;
  }
}
