@import "variables";

body {
  margin: 0;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: $text-color;
}

a {
  text-decoration: none;
  color: $text-color;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.is-checkout {
  background-color: $light-gray;
}
