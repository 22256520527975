@import "variables";

.row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.lg100 {
  @include laptop {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.lg66 {
  @include laptop {
    flex: 1 1 66.3%;
    max-width: 66.3%;
  }
}

.lg50 {
  @include laptop {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.lg33 {
  @include laptop {
    flex: 1 1 33.3%;
    max-width: 33.3%;
  }
}

.md100 {
  @include tablet {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.md66 {
  @include tablet {
    flex: 1 1 66.3%;
    max-width: 66.3%;
  }
}

.md50 {
  @include tablet {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.md33 {
  @include tablet {
    flex: 1 1 33.3%;
    max-width: 33.3%;
  }
}

.sm100 {
  @include mobile {
    flex: 1 1 100%;
  }
}

.sm66 {
  @include mobile {
    flex: 1 1 66.3%;
    max-width: 66.3%;
  }
}

.sm50 {
  @include mobile {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

.sm33 {
  @include mobile {
    flex: 1 1 33.3%;
    max-width: 33.3%;
  }
}

.reverseWrap {
  flex-wrap: wrap-reverse;
}
