@import "variables";

.title {
  font-weight: 700;
  margin-bottom: $margin-base;
}

.address {
  padding: $padding-base * 2;
  margin-top: $margin-base * 2;
  border: 1px solid $medium-dark-gray;
  border-radius: 4px;
}

.addressesWrapper {
  display: flex;
}

.addresses {
  display: flex;
  margin: auto;

  button {
    margin-top: 20px;
  }

  @include mobile {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  @include not-mobile {
    > div + div {
      margin-left: 20px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: $padding-base * 2;
  margin-top: auto;

  button:nth-child(2) {
    margin-left: $margin-base * 2;
  }

  @include mobile {
    justify-content: center;
  }
}

.unverifiedText {
  color: $error-color;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.invalidZip {
  color: $error-color;
  font-weight: 700;
}
